<template>
  <div class="tep-legal">
    <vue-pdf-embed :source="source"></vue-pdf-embed>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      source:
        'https://u8db3x7279cw66r74nmk.s3.eu-west-3.amazonaws.com/CGUV+TEP+-+V7+17022024.pdf',
    }
  },
}
</script>

<style scoped lang="scss">
.tep-legal {
  max-width: 1000px;
  width: 95%;
  margin: auto;
}
</style>
